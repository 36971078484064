import { CanActivateFn, CanMatchFn, Router } from '@angular/router';
import { inject } from '@angular/core';
import { APP_ROUTES } from '@shared/constant/app-routes';
import { concatMap, iif, mergeMap, of } from 'rxjs';
import { AuthStoreService } from '@core/services/auth-service/auth-store.service';
import { PermissionOptions } from '@shared/model/permission-options.model';
import { hasPermissions } from '@shared/utils/parse/has-permission-util';

type PermissionGuardParam =
  | string
  | string[]
  | null
  | PermissionOptions
  | PermissionOptions[];
export const permissionsGuard =
  (allowedPermissions: PermissionGuardParam): CanMatchFn =>
  () => {
    return checkPermission(allowedPermissions);
  };

export const permissionActivateGuard =
  (allowedPermissions: PermissionGuardParam): CanActivateFn =>
  () =>
    checkPermission(allowedPermissions);
const checkPermission = (allowedPermissions: PermissionGuardParam) => {
  const authStoreService = inject(AuthStoreService);
  const router = inject(Router);
  let permissionOptions: PermissionOptions[] = [];
  if (!allowedPermissions) return of(true);
  if (Array.isArray(allowedPermissions)) {
    permissionOptions = allowedPermissions.map((item) => {
      if (typeof item === 'string') return { code: item };
      return item;
    });
  } else {
    if (typeof allowedPermissions === 'string') {
      permissionOptions = [
        {
          code: allowedPermissions,
        },
      ];
    } else {
      permissionOptions = [allowedPermissions];
    }
  }
  if (!permissionOptions.length) return of(true);
  return authStoreService.isAuthenticated().pipe(
    concatMap((isAuth) => {
      if (isAuth) {
        return authStoreService.permissions$.pipe(
          mergeMap((permissions) =>
            iif(
              () =>
                permissionOptions.some((value) =>
                  hasPermissions(permissions, value)
                ),
              of(true),
              of(router.createUrlTree(['/', APP_ROUTES.forbidden]))
            )
          )
        );
      } else {
        return of(true);
      }
    })
  );
};
